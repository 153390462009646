/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react"

declare global {
  interface Window {
    gtmDidInit?: boolean
    dataLayer: any[]
  }
}
  
const Analytics = () => {

  useEffect(() => {
    const loadGTM = (): void => {
      if (typeof window.gtmDidInit === 'undefined') {
        window.gtmDidInit = false
      }
      if (window.gtmDidInit) {
        return
      }
      window.gtmDidInit = true

      const script = document.createElement('script')
      script.type = "text/javascript"
      script.async = true
      script.onload = () => {
        window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
      };
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-P978LR"
      document.head.appendChild(script)
    }

    const initGTMOnEvent = (event: Event): void => {
      loadGTM();
      (event.currentTarget as HTMLElement).removeEventListener(event.type, initGTMOnEvent);
    }

    const timeoutId = setTimeout(loadGTM, 3500) 

    const events = ['scroll', 'mousemove', 'touchstart']
    events.forEach(event => window.addEventListener(event, initGTMOnEvent, {once: true}))
  
    return () => {
      clearTimeout(timeoutId)
      events.forEach(event => window.removeEventListener(event, initGTMOnEvent))
    }
  }, [])
}

export default Analytics